import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import dynamic from 'next/dynamic'
import React, { ElementType, useMemo } from 'react'

import Fallback from '@/components/Fallback'
import Login2FAForm from '@/components/Forms/Login2FAForm'
import LoginForm from '@/components/Forms/LoginForm'

import { useUser } from '@/bus/user'
import { routes } from '@/config'
import Footer from '@/contents/Login/Footer'
import Header from '@/contents/Login/Header'
import { DoublePanel } from '@/layouts'

type LoginType = React.FunctionComponent & {
  Header: React.FC
  Footer: React.FC
}

const Login: LoginType = () => {
  const { t } = useTranslation()
  const { isAuthorized, is2FA } = useUser()

  if (isAuthorized) {
    location.href = routes.SERVICES
  }

  const RandomColorCard = useMemo(
    () =>
      dynamic(() => import(`@/components/ColorCard/RandomColorCard`), {
        ssr: false,
        loading: () => <Fallback />
      }) as ElementType,
    []
  )

  return (
    <>
      <NextSeo title={t('auth:signin.login.head.title') as string} />

      <DoublePanel side={<RandomColorCard />}>
        {is2FA ? <Login2FAForm /> : <LoginForm />}
      </DoublePanel>
    </>
  )
}

Login.Header = Header
Login.Footer = Footer

export default Login
